import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/Header";

const firebaseConfig = require("../firebaseConfig.js");
const { initializeApp } = require("firebase/app");
const {
  getFirestore,
  collection,
  where,
  query,
  getDocs,
} = require("firebase/firestore");

const BacaKomik = () => {
  const { judulKomik, nomorChapter } = useParams();
  const [chapterData, setChapterData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [scrolling, setScrolling] = useState(false);
  const [scrollSpeed, setScrollSpeed] = useState(0);
  const [speed, setSpeed] = useState("none");
  const [showOptions, setShowOptions] = useState(false);
  const [showSpeedButton, setShowSpeedButton] = useState(true); // Added state to show/hide button

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
  
        const sanitizedJudulKomik = judulKomik.replace(/\s+/g, "");
        const sanitizedNomorChapter = nomorChapter.replace(/\s+/g, "");
  
        // Cari di koleksi pertama: komik_image
        let q = query(
          collection(db, "komik_image"),
          where("path", "==", `${sanitizedJudulKomik}-${sanitizedNomorChapter}`)
        );
  
        let querySnapshot = await getDocs(q);
  
        // Jika data tidak ditemukan di koleksi pertama, cari di koleksi kedua: v2-komik_image
        if (querySnapshot.empty) {
          q = query(
            collection(db, "v2-komik_image"),
            where("image_path", "==", `${sanitizedJudulKomik}-${sanitizedNomorChapter}`)
          );
          querySnapshot = await getDocs(q);
        }
  
        // Jika data ditemukan di salah satu koleksi
        if (!querySnapshot.empty) {
          const data = querySnapshot.docs[0].data();
          setChapterData(data);
  
          // Simpan ke history
          const historyItem = { title: data.judulKomik, chapter: data.chapter };
          const existingHistory = JSON.parse(localStorage.getItem("history")) || [];
          const isExisting = existingHistory.some(
            (item) => item.title === historyItem.title && item.chapter === historyItem.chapter
          );
  
          if (!isExisting) {
            existingHistory.push(historyItem);
            localStorage.setItem("history", JSON.stringify(existingHistory));
          }
        } else {
          // Jika data tidak ditemukan di kedua koleksi
          console.log("Chapter tidak ditemukan.");
          navigate(`/not-found?judulKomik=${encodeURIComponent(sanitizedJudulKomik)}`);
        }
      } catch (err) {
        console.error("Error getting documents: ", err);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [judulKomik, nomorChapter, navigate]);

  useEffect(() => {
    let scrollInterval;
    if (scrolling && speed !== "none") {
      const speedMap = {
        slow: 20,
        medium: 10,
        fast: 5,
      };
      const intervalSpeed = speedMap[speed];
      scrollInterval = setInterval(() => {
        window.scrollBy(0, 1);
      }, intervalSpeed);
    }
    return () => clearInterval(scrollInterval);
  }, [scrolling, speed]);

  const startScroll = (selectedSpeed) => {
    setSpeed(selectedSpeed);
    setScrolling(true);
  };

  const stopScroll = () => {
    setScrolling(false);
    setSpeed("none");
  };

  const handleSpeedChange = (selectedSpeed) => {
    if (selectedSpeed === "stop") {
      stopScroll();
    } else {
      startScroll(selectedSpeed);
      setShowOptions(false);
    }
  };

  const handleCloseOptions = () => {
    setShowOptions(false);
  };

  const handleCloseSpeedButton = () => {
    setShowSpeedButton(false); // Hide speed button
  };

  if (loading) {
    return (
      <div className="loading-container flex justify-center items-center h-64">
        <div className="flex items-center space-x-4 bg-gray-200 text-gray-800 font-semibold py-3 px-6 rounded-lg shadow-lg">
          <svg
            className="animate-spin h-5 w-5 text-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
            ></path>
          </svg>
          <span>Segera menampilkan gambar...</span>
        </div>
      </div>
    );
  }

  if (!chapterData) {
    return <div>Chapter tidak ditemukan</div>;
  }

  const handleTitleClick = () => {
    navigate(`/${judulKomik}`);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          {chapterData.title} - {chapterData.chapter} di BebasBacaKomik.com
        </title>
        <meta
          name="description"
          content={`Baca Komik ${chapterData.title} ${chapterData.chapter} di BebasBacaKomik.com`}
        />
        <meta
          name="keywords"
          content={`baca komik, ${chapterData.title}, ${chapterData.chapter}, 
          komik online, manga, manhua, manhwa`}
        />
        <meta
          property="og:title"
          content={`${chapterData.title} - ${chapterData.chapter}`}
        />
        <meta
          property="og:description"
          content={`Baca komik ${chapterData.title} ${chapterData.chapter} online di website kami.`}
        />
        <meta property="og:image" content={chapterData.images[0]} />
      </Helmet>
      <div>
        <Header />

        <div style={{ textAlign: "center" }}>
          <div className="flex flex-col items-center my-5 px-4">
            <h1
              className="font-medium text-center mb-2"
              style={{ fontSize: "1rem" }}
            >
              <a
                href="#"
                onClick={handleTitleClick}
                className="font-bold text-blue-600 hover:text-blue-800 transition duration-300 flex items-center space-x-1"
              >
                {chapterData.judulKomik} 
              </a>
              Cara Baca : {chapterData.readDirection}
            </h1>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {chapterData.images.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                alt={`Image ${index}`}
                style={{ maxWidth: "100%", marginBottom: "10px" }}
              />
            </div>
          ))}
        </div>
        <div style={{ textAlign: "center" }}>
          <div className="flex flex-col items-center my-5 px-4">
            <span className="font-medium text-center mb-2">
              Baca Chapter selanjutnya dari
            </span>
            <a
              href="#"
              onClick={handleTitleClick}
              className="font-bold text-blue-600 hover:text-blue-800 transition duration-300 flex items-center space-x-1"
            >
              <span className="text-center">{chapterData.judulKomik}</span>
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 7l5 5m0 0l-5 5m5-5H6"
                />
              </svg>
            </a>
          </div>
        </div>

        {/* Floating Auto Scroll Button with "x" button */}
        {showSpeedButton && (
          <div
            style={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              zIndex: "1000",
            }}
          >
            <div className="relative">
              <button
                onClick={() => setShowOptions(!showOptions)}
                className="bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 transition-transform duration-300 ease-in-out transform hover:scale-105"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 4h16v16H4V4zm8 8l4 4m0 0l4-4m-4 4V8"
                  />
                </svg>
              </button>
              <button
                onClick={handleCloseSpeedButton}
                className="absolute -right-2 -top-2 bg-red-600 text-white p-1 rounded-full shadow-lg hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-300 transition-transform duration-300 ease-in-out transform hover:scale-105"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              {showOptions && (
                <div className="absolute bottom-16 right-0 bg-white border rounded-lg shadow-lg p-4">
                  <button
                    onClick={handleCloseOptions}
                    className="absolute top-2 right-2 bg-red-600 text-white p-1 rounded-full shadow-lg hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <div className="flex flex-col">
                    <button
                      onClick={() => handleSpeedChange("slow")}
                      className="py-2 px-4 mb-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300 transition-colors duration-300"
                    >
                      Slow
                    </button>
                    <button
                      onClick={() => handleSpeedChange("medium")}
                      className="py-2 px-4 mb-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300 transition-colors duration-300"
                    >
                      Medium
                    </button>
                    <button
                      onClick={() => handleSpeedChange("fast")}
                      className="py-2 px-4 mb-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300 transition-colors duration-300"
                    >
                      Fast
                    </button>
                    <button
                      onClick={() => handleSpeedChange("stop")}
                      className="py-2 px-4 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-4 focus:ring-red-300 transition-colors duration-300"
                    >
                      Stop
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </HelmetProvider>
  );
};

export default BacaKomik;
